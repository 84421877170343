import '../css/application';
require.context('../assets', true)

import Rails from '@rails/ujs';
import Turbolinks from 'turbolinks';
import flatpickr from 'flatpickr';

Rails.start();
Turbolinks.start();

require('trix');
require('@rails/actiontext');

// Trix.config.attachments.preview.caption = { name: false, size: false }

document.addEventListener('turbolinks:load', () => {
  setTimeout(() => document.querySelector('.flash-msg')?.remove(), 5000);
  flatpickr('.date-picker', { enableTime: true, time_24hr: true });
});
